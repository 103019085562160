<template>
    <v-container  >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="areaForm" v-model="valid"          >
            <v-row>
                <v-col align-self="center">
                    <v-text-field dense outlined v-if="!loading" v-model="form.name" :counter="30" :rules="nameRules" :label="$store.getters.getTextMap().area_name" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-text-field dense outlined v-if="!loading" v-model="form.msg" :counter="30" :rules="msgRules" :label="$store.getters.getTextMap().description" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="form.area_ref"
                        :items="areaRefOptions"
                        :label="$store.getters.getTextMap().area_reference"
                        outlined
                        dense
                        item-text="label"
                        item-value="value"
                        required
                        :rules="selectRules"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn style="margin-left:20px;"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container> 
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateArea',
    props:['area'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.area && this.area.area_id){
            this.form=Object.assign({},this.area)
            this.edit=true
            this.api='updateArea'
        }else{
            this.edit=false
            this.api='createArea'
        }
    },
    data(){
        return {
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            api:'createArea',
            form:{
                name:null,
                area_ref:'air_quality',
                meta:null,
                msg:null, 
            },
            areaRefOptions:[
                {label:'Air Quality', value:'air_quality'},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 55 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6)
        }
    },
    methods:{
        onSubmit(){
            this.$refs.areaForm.validate()
            if(this.valid){
                this.loading=true
                axios.post(this.$store.state.api+this.api,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.$store.dispatch('refreshAreas')
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>