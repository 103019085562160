<template>
    <div>
        <v-btn 
            :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  
            @click="dialog = !dialog"
        >
        {{ mode }} {{$store.getters.getTextMap().premise_area}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-map-marker</v-icon>
        </v-btn>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ mode }}  {{$store.getters.getTextMap().premise_area}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false"> {{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <CreateArea :area="area" v-on:close="dialog=false"/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CreateArea from '@/components/crud_components/application/CreateArea'
export default {
    name:'CreateAreaModal',
    props:['area'],
    components:{
        CreateArea  
    },
    data(){
        return {
            dialog:false,
            mode:this.$store.getters.getTextMap().add
        }
    },
    mounted(){
        if(this.area && this.area.area_id){
            this.mode = this.$store.getters.getTextMap().edit
        }
    }
}
</script>

  