<template>
    <v-container fluid >
        <v-container class="pa-1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
            <v-row>
                <v-col align="right" align-self="center">
                    <CreateAreaModal/>
                </v-col>
            </v-row>
        </v-container>
        <v-row no-gutters>
            <v-col>
                <v-sheet>
                <v-tabs
                    :dark="$store.getters.getColorPalette().isDark"
                    :background-color="$store.getters.getColorPalette().backgroundColorCode"
                    v-model="tab"
                    show-arrows
                >
                    <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
                    <router-link  v-for="areas in $store.state.areas" :key="areas.area_id"  :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="`/airquality/${areas.area_id}`">
                        <v-tab>{{areas.name}}</v-tab>
                    </router-link>
                </v-tabs>
            </v-sheet>
                <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.areas.length==0">
            <v-col align="center">
                <v-icon large :color="$store.getters.getColorPalette().accentCode">mdi-hail<v-spacer></v-spacer></v-icon>
                <h3 :style="{color:$store.getters.getColorPalette().accentCode}" >Not Configured</h3>
            </v-col>
        </v-row>
        <router-view></router-view>
    </v-container>
</template>
<script>
import CreateAreaModal from '@/components/modals/application/CreateAreaModal'
export default {
    name:'AirQuality',
    components:{
        CreateAreaModal,
    },
    data(){
        return {
            tab:null,
        }
    },
    mounted(){
        document.title='Air Quality'
        if (!this.$store.state.loggedIn) {
            this.$router.push("/");
        }
        if (this.$store.state.areas && this.$store.state.areas.length > 0) {
            const firstAreaId = this.$store.state.areas[0].area_id;
            this.$router.push(`/airquality/${firstAreaId}`);
        }
    },
}
</script>